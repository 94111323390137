import { memo } from "react";

import styles from "./ArticleSummary.module.css";

function Disclaimer({ hidden }: { hidden: boolean }) {
  return (
    <div className={styles.disclaimer} hidden={hidden}>
      <span className={styles.text} data-nosnippet={true}>
        Oppsummeringen er laget med kunstig intelligens og{" "}
        <a href="https://www.vg.no/informasjon/redaksjonelle-avgjorelser/183">
          kvalitetssikret av VGs journalister.
        </a>
      </span>
    </div>
  );
}

export default memo(Disclaimer);
