import { memo, useCallback, useMemo } from "react";

import { Button, Text } from "@vgno/core";
import { useToggle } from "@vgno/hooks";
import { Ai, CloseFill } from "@vgno/icons";
import { classnames } from "@vgno/utils";

import { ExpandableBox } from "../ExpandableBox";

import Disclaimer from "./Disclaimer";

import type { CapiTextComponent, ListBlock } from "vg";

import styles from "./ArticleSummary.module.css";

type ArticleSummaryProps = {
  articleSummary?: ListBlock[];
  type?: string;
};

function ArticleSummary({ articleSummary, type }: ArticleSummaryProps) {
  const [showInfo, toggleShowInfo] = useToggle(false);
  const onShowInfoClick = useCallback(() => toggleShowInfo(), [toggleShowInfo]);

  const component: CapiTextComponent = useMemo(
    () => ({
      paragraphs: articleSummary ?? [],
      type: "text" as const,
    }),
    [articleSummary],
  );

  if (component.paragraphs.length === 0) return null;

  return (
    <div
      className={classnames(
        styles.summary,
        "layout-component",
        "layout-normal",
        "layout-padded",
      )}
      {...(type !== "feature" && { "data-theme": "accent" })}
    >
      <ExpandableBox
        className={styles.box}
        data-track-element-type="Article Summary"
        data-track-id="article-summary"
        data-track-impression
        data-track-name="Article Summary"
        type={type}
        mustExpand={true}
      >
        <h2
          className={classnames(
            styles.title,
            "title-large font-weight-semi-bold",
          )}
        >
          Kortversjonen
        </h2>
        <Button
          aria-label="Vis informasjon"
          className={classnames(styles.button, showInfo && styles.active)}
          data-track-id="article-summary-disclaimer"
          data-track-name="Article Summary Disclaimer"
          onClick={onShowInfoClick}
        >
          {showInfo ? <CloseFill /> : <Ai />}
        </Button>
        <Disclaimer hidden={!showInfo} />
        <Text
          component={component}
          styles={{
            item: styles.item,
            list: styles.list,
            paragraph: styles.paragraph,
            unordered: styles.unordered,
          }}
        />
      </ExpandableBox>
    </div>
  );
}

export default memo(ArticleSummary);
